<template lang="pug">
.product__gallery(:class="{'product__gallery_empty' : images.length === 0}")
  template(v-if="images.length")
    .product__gallery-item(v-for="(image, i) in images", :key="i")
      .product__signs(v-if="(discount || isHit || isNew) && i === 0")
        span.product__sign.product__sign_discount(v-if="discount") {{ discount }}
        span.product__sign.product__sign_hit(v-if="isHit") хит
        span.product__sign.product__sign_new(v-if="isNew") new
      picture.product__gallery-picture
        source(:srcset="`${image.w272} 1x, ${image.w544} 2x`", type="image/webp", media="(max-width: 700px)")
        source(:srcset="`${image.w544} 1x, ${image.w816} 1.5x`", type="image/webp", media="(max-width: 1240px)")
        source(:srcset="`${image.w376} 1x, ${image.w752} 2x`", type="image/webp", media="(max-width: 1700px)")
        source(:srcset="`${image.w528} 1x, ${image.w792} 1.5x`", type="image/webp")
        source(:srcset="`${image.j528} 1x, ${image.j792} 1.5x`")
        img.product__gallery-image(:src="image.j528", :alt="alt", loading="lazy")
  .product__gallery-item(v-else)
    picture.product__gallery-picture
      img.product__gallery-image(src="/images/placeholder-logo.svg", alt="")
  .product__video(v-for="(video, i) in videos", :key="i")
    iframe.product__video-frame(
        :src="video.video"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        loading="lazy"
      )
</template>

<script>
export default {
  props: {
    images: { type: Array, default () { return [] } },
    videos: { type: Array, default () { return [] } },
    productName: { type: String, required: true },
    discount: { type: String },
    isHit: { type: Number },
    isNew: { type: Number }
  },
  name: 'TheProductGallery',
  computed: {
    alt () {
      return `${this.productName} - фото`
    }
  }
}
</script>
