<template lang="pug">
router-link.tile-item(:to="{ name: 'Product', params: { slug } }")
  .tile-item__signs(v-if="priceOld || isHit || isNew" )
    span.tile-item__sign.tile-item__sign_discount(v-if="priceOld") {{ sale }}%
    span.tile-item__sign.tile-item__sign_hit(v-if="isHit") хит
    span.tile-item__sign.tile-item__sign_new(v-if="isNew") new
  picture.tile-item__picture(v-if="image")
    source(:srcset="`${ image.w158 } 1x, ${ image.w316 } 2x`", type="image/webp", media="(max-width: 520px)")
    source(:srcset="`${ image.w224 } 1x, ${ image.w448 } 2x`", type="image/webp", media="(max-width: 700px)")
    source(:srcset="`${ image.w202 } 1x, ${ image.w404 } 2x`", type="image/webp", media="(max-width: 950px)")
    source(:srcset="`${ image.w270 } 1x, ${ image.w540 } 2x`", type="image/webp", media="(max-width: 1240px)")
    source(:srcset="`${ image.w276 } 1x, ${ image.w414 } 1.5x`", type="image/webp", media="(max-width: 1700px)")
    source(:srcset="`${ image.w252 } 1x, ${ image.w378 } 1.5x`", type="image/webp")
    source(:srcset="`${ image.j252 } 1x, ${ image.j378 } 1.5x`")
    img.tile-item__image(:src="`${ image.j252}`", :alt="`${name} фото`", loading="lazy")
  picture.tile-item__picture(v-else)
    img.tile-item__image(src="/images/placeholder-logo.svg", alt="")
  .tile-item__box
    .tile-item__price
      span.tile-item__price-current.text-big от {{ price.toLocaleString() }} ₽
      span.tile-item__price-old.text-small(v-if="priceOld") {{ priceOld.toLocaleString() }} ₽
    span.tile-item__out-of-stock(v-if="!inStock && !isChiedoProduct && !isAvailableForCart") Нет в наличии
  span.tile-item__name.text {{ name }}
</template>

<script>
import '@/assets/styles/components/tile-item.sass'

export default {
  props: {
    slug: { type: String, required: true },
    name: { type: String, required: true },
    price: { type: Number, required: true },
    priceOld: Number,
    image: { type: Object, required: true },
    isHit: { type: [Number, Boolean] },
    isNew: { type: [Number, Boolean] },
    inStock: { type: [Number, Boolean], default: true },
    isChiedoProduct: { type: Boolean },
    isAvailableForCart: { type: Boolean }
  },
  name: 'TileItem',
  computed: {
    sale () {
      return Math.ceil(this.price / this.priceOld * 100 - 100) * -1
    }
  }
}
</script>
