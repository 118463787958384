<template lang="pug">
head-meta(
    :title-page="titlePage",
    :description="descriptionPage",
    :og="og"
  )
spinner(v-if="isLoading")
template(v-if="!isNotFound")
  crumbs(
      v-if="product && product.breadcrumb.length",
      :breadcrumbs="product.breadcrumb"
    )
  the-product(
    :product-start="product",
    @add-in-the-cart="addInTheCart"
  )
  template(v-if="product")
    want-another(v-if="product?.is_chiedo_product", :slug="this.$route.params.slug")
    tile-list(v-if="attendantProducts.length", block-name="С этим товаром покупают", :products="attendantProducts", page="product")
    tile-list(v-if="similarProducts.length", block-name="Похожие товары", :products="similarProducts", page="product")
    tile-list(v-if="historyProducts.length", block-name="Вы недавно смотрели", :products="historyProducts", page="product")
thanks404(v-else, mod="error")
</template>

<script>
import TheProduct from '../components/product/TheProduct'
import TileList from '../components/_tiles/TileList'
import Crumbs from '../components/Crumbs'
import { getProduct, getMoreProducts, refreshProduct } from '../assets/scripts/Api'
import HeadMeta from '../components/_layout/HeadMeta'
import { phone } from '../assets/scripts/variables'
import Thanks404 from '../components/Thanks404'
import WantAnother from '@/components/product/WantAnother'
import Spinner from '../components/Spinner'

export default {
  name: 'ProductPage',
  components: { WantAnother, Thanks404, HeadMeta, TileList, TheProduct, Crumbs, Spinner },
  data () {
    return {
      product: null,
      productMore: {},
      similarProducts: [],
      attendantProducts: [],
      historyProducts: [],
      isNotFound: false,
      isLoading: false,
      phone
    }
  },
  computed: {
    titlePage () {
      if (this.isNotFound) return 'Страница не найдена'
      return this.product ? `Купить ${this.product.name} по цене ${this.product.price_current} ₽ в каталоге ChiedoHome` : ''
    },
    descriptionPage () {
      if (this.isNotFound) return 'Проверьте ссылку на страницу, возможно она была удалена или перемещена'
      return this.product ? `⭐⭐⭐⭐⭐ Купить ${this.product.name} в интернет-магазине ChiedoHome. ✅ Быстрая доставка, отзывы, гарантия. Звоните ☎ ${phone.mask}.` : ''
    },
    og () {
      if (this.isNotFound) return null
      if (!this.product) return null
      return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: this.product.name,
        description: this.stripHtmlDescription,
        image: this.product.images[0].j792,
        offers: {
          '@type': 'Offer',
          price: this.product.price_current,
          priceCurrency: 'RUB',
          availability: this.product?.stock ? 'http://schema.org/InStock' : 'https://schema.org/BackOrder'
        }
      })
    },
    stripHtmlDescription () {
      return this.product.description
        .replace(/(<([^>]+)>)/gi, ' ')
        .replace(/\s+/g, ' ')
        .trim()
    }
  },
  methods: {
    async getDataPage (slug) {
      this.isLoading = true
      const data = await getProduct(slug)
      if (!data || data.status === 'error') this.isNotFound = true
      this.product = data
      this.isLoading = false
    },
    async getMoreProducts (slug) {
      const data = await getMoreProducts(slug)
      this.placeMoreProducts(data)
    },
    addInTheCart () {
      refreshProduct(this.$route.params.slug)
    },
    placeMoreProducts (data) {
      this.similarProducts = data.similar ? data.similar : []
      this.attendantProducts = data.attendant ? data.attendant : []
      this.historyProducts = data.history ? data.history : []
    },
    getPreloadData (route) {
      const app = document.querySelector('#app')
      const product = app.dataset.product
      if (product && JSON.parse(product).slug === route.params.slug) {
        this.product = JSON.parse(product)
        const moreProducts = app.dataset.productMore
        moreProducts ? this.placeMoreProducts(JSON.parse(moreProducts)) : this.getMoreProducts(route.params.slug)
      } else {
        this.getDataPage(route.params.slug)
        this.getMoreProducts(route.params.slug)
      }
    }
  },
  mounted () {
    this.getPreloadData(this.$route)
  },
  // async beforeRouteEnter (to, from, next) {
  //   await getProduct(to.params.slug)
  //   next()
  // },
  beforeRouteUpdate (to, from, next) {
    this.isNotFound = false
    this.getPreloadData(to)
    next()
  }
}
</script>
