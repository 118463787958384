<template lang="pug">
.crumbs
  .crumbs__cont.cont
    ul.crumbs__list
      li.crumbs__item
        router-link.crumbs__link.link_black-orange.text-tiny(:to="{ name: 'Home' }") Чиедо Хоум
      li.crumbs__item(v-for="category in breadcrumbs", :key="category.id")
        router-link.crumbs__link.link_black-orange.text-tiny(:to="{ name: 'Catalog', params: { slug: category.slug } }") {{ category.name }}
      li.crumbs__item(v-if="currentPage > 1")
        span.crumbs__link.text-tiny Страница {{currentPage}}
</template>

<script>
export default {
  props: {
    breadcrumbs: { type: Array, default () { return [] } },
    currentPage: Number
  },
  name: 'Crumbs'
}
</script>
