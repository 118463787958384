<template lang="pug">
.want-another(@submit.prevent="sendForm")
  .want-another__cont.cont
    .want-another__wrapper
      h2.want-another__title Нужно такое, но что-то другое?
      p.want-another__text Оставьте заявку, менеджер свяжется, выслушает и поможет реализовать идею
      form.want-another__form(method="post")
        input.want-another__input.input.text(
          v-model="phone",
          id="user-phone",
          type="tel",
          pattern="[0-9-+() ]*",
          placeholder="Телефон",
          required,
          :disabled="disabledPhoneInput"
        )
        .want-another__button-box
          button.want-another__submit.button-orange(type="submit" :disabled="disabledSubmitButton") Оставить заявку
          span.want-another__policy-text.text-tiny и принять условия&thinsp;
            router-link.want-another__policy-link(:to="{ name: 'Policy'}") Политики конфиденциальности
</template>

<script>
import '@/assets/styles/components/want-another.sass'
import { sendProductForm } from '@/assets/scripts/Api'
import Storage from '@/assets/scripts/Storage'

export default {
  name: 'WantAnother',
  data () {
    return {
      phone: null,
      disabledPhoneInput: false,
      disabledSubmitButton: false
    }
  },
  props: {
    slug: { type: String, required: true }
  },
  methods: {
    async sendForm () {
      this.disableForm()
      const res = await sendProductForm(this.slug, this.phone)
      if (res.status === 'success') {
        Storage.showThanks = true
        await this.$router.push({ name: 'Thanks' })
        return
      }
      this.enableForm()
    },
    disableForm () {
      this.disabledPhoneInput = true
      this.disabledSubmitButton = true
    },
    enableForm () {
      this.disabledPhoneInput = false
      this.disabledSubmitButton = false
    }
  }
}
</script>

<style scoped>

</style>
