<template lang="pug">
aside.product-labels
  .product-labels__item(v-for="(item, index) in labels", :key="index")
    img.product-labels__image(:src="item.image", :alt="item.text", loading='lazy')
    span.product-labels__text.text {{item.text}}
</template>

<script>
import '@/assets/styles/components/product-labels.sass'
export default {
  name: 'TheProductLabels',
  props: {
    labels: Array
  }
}
</script>
