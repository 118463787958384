<template lang='pug'>
div.spinner
  span.spinner__item(v-for="i in 12", :key="i")
</template>

<script>
import '@/assets/styles/components/spinner.sass'

export default {
  name: 'Spinner'
}
</script>
