<template lang="pug">
article.tile-list(:class= "page ? `tile-list_${page}`: ''")
  .tile-list__cont.cont
    h2.tile-list__title.title(v-if="blockName") {{blockName}}
    template(v-if="products.length")
      .tile-list__list
        tile-item(
          v-for="product in products",
          :key="product.id",
          :slug="product.slug",
          :name="product.name",
          :price="product.price_current",
          :price-old="product.price_old",
          :image="product.image",
          :is-hit="product.is_designers_choice",
          :is-new="product.is_new",
          :in-stock="product.stock",
          :is-chiedo-product="product.is_chiedo_product"
          :is-available-for-cart="product.is_available_for_cart"
        )
    //a.tile-big__link(:to="{ name: 'Home' }" :class="mod === 'small' ? 'tile-big__link_hide': ''" v-if="button") {{button}}
</template>

<script>
import '@/assets/styles/components/tile-list.sass'

import TileItem from './TileItem'

export default {
  name: 'TileList',
  components: { TileItem },
  props: {
    blockName: { type: String },
    button: { type: String },
    page: { type: String, default: 'category' },
    products: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>
