<template lang="pug">
article.product(v-if="product")
  .product__cont.cont
    .product__area
      .product__body
        the-product-gallery(
            :images="product.images",
            :videos="product.videos",
            :product-name="product.name",
            :discount="discount",
            :isHit="product.is_designers_choice",
            :isNew="product.is_new"
          )
      .product-card__sidebar
        .product-card__sidebar-scroll-inner
          h1.product-card__sidebar-name {{ product.name }}
            |
            a(v-if="product.backend_link", :href="product.backend_link", target="_blank") #
          .product-card__price-box
            .product-card__price-line(v-if="product.price_current")
              span.product-card__price от {{ product.price_current.toLocaleString('ru-RU') }} ₽
              template(v-if="product.price_old")
                span.product-card__price-old {{ product.price_old.toLocaleString('ru-RU') }} ₽
                //span.product-card__discount {{ discount }}
              span.product-card__out-of-stock(v-if="!availableForBuy") Нет в наличии
          template(v-if="availableForBuy" )
            .product-card__cart-box
              button.product-card__cart-button.text.button-orange(type="button", v-if="!isAdded", @click="addInTheCart") В корзину
              router-link.product-card__cart.text.button-white(:to="{ name: 'Basket' }", v-else) Перейти в корзину
            .product-card__deadline
              span.product-card__deadline-text.text Доставим
              span.product-card__deadline-date.text {{dateInThreeWeeks.toLocaleString('ru', { month: 'long', day: 'numeric' })}}
          template(v-else)
            form.product-card__form(@submit="sendProductAvailabilityForm")
              input.product-card__input.input.text(
                  type="email",
                  required,
                  placeholder="E-mail",
                  v-model="email",
                  :disabled="isDisabled"
                )
              button.product-card__submit.text.button-green(
                  type="submit",
                  :disabled="isDisabled"
                ) Сообщить о поступлении
          .product-card__view(v-for="(relate, i) in product.related", :key="i")
            .product-card__view-name.text {{ relate.group_name }}
            .product-card__view-list
              router-link.product-card__view-item(v-for="relate_product in relate.products", :key="relate_product.id" :to="{ name: 'Product', params: {slug: relate_product.slug} }" active-class="product-card__view-item_active" )
                picture.product-card__view-picture
                  source(:srcset="`${relate_product.images.w65} 1x, ${relate_product.images.w130} 2x`" type="image/webp")
                  source(:srcset="`${relate_product.images.j65} 1x, ${relate_product.images.j130} 2x`")
                  img.product-card__view-image(:src="`${relate_product.images.j65}`" loading="lazy")
          .product-card__wrapper(v-if="product.description|| product.attributes && product.attributes.length")
            .product-card__descr#product-card__descr(v-if="product.description || attributesWithValues.length")
              h2.product-card__title Характеристики товара
              .product-card__text(v-if="product.description", v-html="product.description")
              .product-card__characteristics-box(v-if="attributesWithValues.length")
                p.product-card__characteristics(v-for="(attribute, i) in attributesWithValues", :key="i")
                  span.product-card__name {{attribute.name}}:
                  span.product-card__value {{attribute.value}} {{attribute.unit}}
          the-product-labels(
            v-if="product.labels && product.labels.length"
            :labels="product.labels"
          )
      //p.product-card__link-item
      //  a.product-card__link.link_black-orange(:href="product.instruction" download v-if="product.instruction") Скачать инструкцию по эксплуатации
      //p.product-card__link-item
      //  a.product-card__link.link_black-orange(:href="product.pasport" download v-if="product.pasport") Скачать паспорт изделия
</template>

<script>
import '@/assets/styles/components/product.sass'
import Crumbs from '../../components/Crumbs'
import TheProductGallery from './TheProductGallery'
import { addProduct, refreshBasket, sendProductAvailabilityForm } from '../../assets/scripts/Api'
import TheProductLabels from '@/components/product/TheProductLabels'

export default {
  components: { TheProductLabels, Crumbs, TheProductGallery },
  name: 'ProductCard',
  props: {
    productStart: { type: Object }
  },
  emits: ['add-in-the-cart'],
  data () {
    return {
      product: null,
      isAdded: false,
      email: '',
      isDisabled: false
    }
  },
  watch: {
    productStart (data) {
      this.product = data
      this.isAdded = !!data.cart_count
    }
  },
  computed: {
    discount () {
      if (!this.product.price_old) return ''
      return Math.round((this.product.price_old - this.product.price_current) * 100 / this.product.price_old) + '%'
    },
    isAddedInTheCart () {
      return this.product.cart_count > 0
    },
    attributesWithValues () {
      return this.product.attributes.filter(item => item.value)
    },
    dateInThreeWeeks () {
      const date = new Date()
      date.setDate(date.getDate() + 21)
      return date
    },
    availableForBuy () {
      return this.product.stock || this.product.is_chiedo_product || this.product.is_available_for_cart
    }
  },
  methods: {
    async addInTheCart () {
      this.isAdded = true
      this.$emit('add-in-the-cart')
      await addProduct(this.product.id)
      refreshBasket()
    },
    async sendProductAvailabilityForm (event) {
      event.preventDefault()
      this.disableForm()
      const res = await sendProductAvailabilityForm(this.$route.params.slug, this.email)
      if (res && res.status === 'success') {
        Storage.showThanks = true
        await this.$router.push({ name: 'Thanks' })
        return
      }
      this.enableForm()
    },
    disableForm () {
      this.isDisabled = true
    },
    enableForm () {
      this.isDisabled = false
    },
    descriptionScroll () {
      const productCardDescr = document.getElementById('product-card__descr')
      window.scrollTo({
        top: window.pageYOffset + productCardDescr.getBoundingClientRect().top,
        behavior: 'smooth'
      })
    },
    setDate () {
      const addDays = 21
      const date = new Date()
      date.setDate(date.getDate() + addDays)
      console.log(date)
    }
  }
}
</script>
